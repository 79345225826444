import {
  DesktopOutlined,
  ShoppingCartOutlined,
  HeatMapOutlined,
  BarChartOutlined,
  UserOutlined,
} from "@ant-design/icons";

const menuList = [
  {
    title: "Đơn Hàng",
    path: "/orders",
    icon: <ShoppingCartOutlined />,
  },
  {
    title: "Vendors",
    path: "/vendors",
    icon: <ShoppingCartOutlined />,
  },
  {
    title: "Shipppers",
    path: "/shippers",
    icon: <DesktopOutlined />,
  },
  {
    title: "Khách hàng",
    path: "/consumers",
    icon: <UserOutlined />,
  },
  {
    title: "Bảng giá",
    path: "/routes",
    icon: <HeatMapOutlined />,
  },
  {
    title: "Thống kê",
    path: "/dashboard",
    icon: <BarChartOutlined />,
  },
];

export default menuList;

import Loadable from 'react-loadable';
import Loading from '../components/Loading';

const Dashboard = Loadable({
  loader: () => import('../views/dashboard'),
  loading: Loading,
});

const Price = Loadable({
  loader: () => import('../views/price'),
  loading: Loading,
});

const Vendors = Loadable({
  loader: () => import('../views/vendors'),
  loading: Loading,
});
const VendorsDetails = Loadable({
  loader: () => import('../views/vendors/VendorDetails'),
  loading: Loading,
});

const Shippers = Loadable({
  loader: () => import('../views/shippers'),
  loading: Loading,
});

const ShippersDetails = Loadable({
  loader: () => import('../views/shippers/ShipperDetails'),
  loading: Loading,
});

const Orders = Loadable({
  loader: () => import('../views/orders'),
  loading: Loading,
});
const OrdersDetails = Loadable({
  loader: () => import('../views/orders/OrderDetails'),
  loading: Loading,
});
const Consumers = Loadable({
  loader: () => import('../views/consumers/List'),
  loading: Loading,
});
const ConsumerDetail = Loadable({
  loader: () => import('../views/consumers/Detail'),
  loading: Loading,
});
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/orders/:id',
    component: OrdersDetails,
  },
  {
    path: '/vendors/:id',
    component: VendorsDetails,
  },
  {
    path: '/shippers/:id',
    component: ShippersDetails,
  },
  { path: '/consumers/:id', component: ConsumerDetail },
  {
    path: '/orders',
    component: Orders,
  },
  {
    path: '/vendors',
    component: Vendors,
  },

  {
    path: '/shippers',
    component: Shippers,
  },
  {
    path: '/routes',
    component: Price,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  { path: '/consumers', component: Consumers },
];

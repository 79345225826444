import React from "react";
import { useHistory } from "react-router-dom";

import { Menu, Dropdown, Modal, Layout, Avatar } from "antd";
import "./index.scss";

const { Header } = Layout;

const LayoutHeader = (props) => {
  const history = useHistory();

  const { token, avatar, sidebarCollapsed, getUserInfo, fixedHeader } = props;
  token && getUserInfo(token);
  const logout = () => {
    localStorage.removeItem("Bearer");
    history.push("/login");
  };
  const handleLogout = (token) => {
    Modal.confirm({
      title: "LOG OUT",
      content: "Are you sure?",
      okText: "Yes",
      cancelText: "cancel",
      onOk: () => {
        logout();
      }
    });
  };
  const onClick = ({ key }) => {
    switch (key) {
      case "logout":
        handleLogout(token);
        break;
      default:
        break;
    }
  };
  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="logout">Log out</Menu.Item>
    </Menu>
  );
  const computedStyle = () => {
    let styles;
    if (fixedHeader) {
      if (sidebarCollapsed) {
        styles = {
          width: "calc(100% - 80px)"
        };
      } else {
        styles = {
          width: "calc(100% - 200px)"
        };
      }
    } else {
      styles = {
        width: "100%"
      };
    }
    return styles;
  };
  return (
    <>
      {fixedHeader ? <Header /> : null}
      <Header
        style={computedStyle()}
        className={fixedHeader ? "fix-header" : ""}
      >
        <div className="right-menu">
          <div className="dropdown-wrap">
            <Dropdown overlay={menu}>
              <div>
                <Avatar shape="square" size="medium" src={avatar} />
                {/* <Icon style={{ color: "rgba(0,0,0,.3)" }} type="caret-down" /> */}
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
    </>
  );
};

export default LayoutHeader;
